body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}
